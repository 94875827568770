<template>
  <div class="container">
    <div class="lang">
      <el-select v-model="value" placeholder="Vui lòng chọn" @change="clickChange">
        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
      </el-select>
    </div>
    <h2>Thỏa thuận cấp phép và dịch vụ phần mềm UniLive</h2>
    <p>
      Giới thiệu<br/>
      Thỏa thuận cấp phép và dịch vụ phần mềm UniLive ("Thỏa thuận này") là PIX Technology PTE.
      Thỏa thuận giữa Phần mềm UniLive (sau đây gọi là "UniLive" hoặc "chúng tôi") và bạn (sau đây gọi là "Người dùng")
      liên quan đến việc bạn tải xuống, cài đặt, đăng nhập, sử dụng Phần mềm và sử dụng các dịch vụ liên quan đến
      UniLive. Vui lòng đọc kỹ nội dung của Thỏa thuận này. Nếu bạn có thắc mắc về nội dung của Thỏa thuận này hoặc
      thông tin nhắc nhở trang, vui lòng không thực hiện bước tiếp theo. Bạn có thể tư vấn thông qua dịch vụ khách hàng
      chính thức của nền tảng UniLive để chúng tôi có thể giải thích và giải thích cho bạn. Bằng cách xác nhận rằng bạn
      đã đồng ý với Thỏa thuận này bằng cách nhấp vào trang hoặc bằng cách trực tiếp bắt đầu sử dụng các dịch vụ liên
      quan đến phần mềm UniLive. br />
    </p>
    <p>
      Nếu bạn là trẻ vị thành niên dưới 18 tuổi hoặc không có năng lực hành vi dân sự đầy đủ do tình trạng sức khỏe trí
      tuệ và tâm thần, vui lòng đọc Thỏa thuận này và các thỏa thuận liên quan khác dưới sự hướng dẫn và đi cùng của
      người giám hộ và đặc biệt chú ý đến việc sử dụng các điều khoản liên quan của trẻ vị thành niên và sử dụng Phần
      mềm UniLive và các dịch vụ liên quan với sự đồng ý của người giám hộ. Đặc biệt lưu ý rằng nếu không có sự đồng ý
      rõ ràng của người giám hộ, bạn được yêu cầu không sử dụng các dịch vụ liên quan đến chi phí nạp tiền do UniLive
      cung cấp và yêu cầu người giám hộ thực hiện nghĩa vụ giám hộ một cách thực tế.
    </p>
    <h3>Thỏa thuận này sẽ giúp bạn hiểu những điều sau đây</h3>
    <p>I. Định nghĩa</p>
    <p>Bảo vệ thông tin cá nhân</p>
    <p>Điều khoản sử dụng trẻ vị thành niên</p>
    <p>Tài sản trí tuệ</p>
    <p>Chương trình khuyến mãi</p>
    <p>XIII Thay đổi, gián đoạn và chấm dứt dịch vụ</p>
    <p>14 Tuyên bố miễn trừ trách nhiệm</p>
    <p>16, Khác</p>
    <h3>I. Định nghĩa</h3>
    <p>
      1. Nền tảng UniLive, đề cập đến UniLiveAPP, cũng như các trang web, trang web, khách hàng, ứng dụng nhỏ và phương
      tiện truyền tải dịch vụ mới có thể được mở sau đó.
    </p>
    <p>
      2. Dịch vụ/Dịch vụ này có nghĩa là các dịch vụ liên quan được cung cấp cho Người dùng thông qua Nền tảng UniLive
      (bao gồm nhưng không giới hạn ở việc cung cấp không gian lưu trữ thông tin, từ đó cung cấp cho Người dùng video âm
      thanh mạng, phát sóng trực tiếp trên web, tìm kiếm, v.v.) và các dịch vụ liên quan khác được cung cấp từ đó hoặc
      cho mục đích này
    </p>
    <p>
      3. Người dùng/người dùng UniLive, bao gồm cả người dùng đã đăng ký và người dùng chưa đăng ký. Người dùng đã đăng
      ký là người dùng đã tạo một tài khoản UniLive để sử dụng một số tính năng và dịch vụ của Nền tảng UniLive; Bất kỳ
      người dùng nào không đăng ký sản phẩm và/hoặc dịch vụ của chúng tôi sẽ trở thành người dùng không đăng ký của
      chúng tôi kể từ khi bắt đầu sử dụng sản phẩm và/hoặc dịch vụ của chúng tôi, trong quá trình sử dụng phải tuân theo
      tất cả các điều khoản khác trong Thỏa thuận này ngoài thỏa thuận dành riêng cho người dùng đã đăng ký.
    </p>
    <p>
      Tài khoản/tài khoản UniLive là tài khoản bạn đăng ký và sử dụng trên nền tảng UniLive, sau khi đăng ký tài khoản,
      bạn có thể thực hiện một hoặc nhiều chức năng như quay video trực tiếp/ngắn, đăng tải, xem, bình luận, chia sẻ,
      tìm kiếm thông qua nền tảng UniLive.
    </p>
    <p>
      5. Quy tắc nền tảng UniLive, bao gồm tất cả các loại điều khoản dịch vụ, quy tắc, thông số kỹ thuật, giải thích
      quy tắc, điều kiện thực hiện, thông báo, thông báo và các tài liệu quy tắc khác mà UniLive đã xuất bản hoặc có thể
      xuất bản, sửa đổi trong tương lai, tùy thuộc vào việc hiển thị trang có liên quan. Bạn cần được nhắc nhở đặc biệt
      rằng khi bạn sử dụng một số sản phẩm và/hoặc dịch vụ cụ thể trong nền tảng UniLive, vui lòng đặc biệt chú ý đến
      các điều khoản hoặc quy tắc dịch vụ có liên quan. Khi bạn đồng ý với Thỏa thuận này hoặc thực sự sử dụng các dịch
      vụ như vậy, bạn được coi là đồng ý với các điều khoản được mô tả ở trên.
    </p>
    <h3>II. Phạm vi áp dụng và sửa đổi của Thỏa thuận.</h3>
    <p>
      Phạm vi đối tượng áp dụng của Thỏa thuận, bạn nên biết rằng Nền tảng UniLive là một Nền tảng Dịch vụ Toàn diện,
      nơi bạn có thể xác định đối tượng vận hành Nền tảng UniLive mà bạn thực hiện dựa trên Thỏa thuận được ký kết bởi
      Dịch vụ tương ứng hoặc thông tin được công bố bởi Dịch vụ tương ứng.
      Theo Thỏa thuận này, các nhà điều hành nền tảng UniLive có thể thay đổi để phù hợp với các điều chỉnh kinh doanh
      của nền tảng UniLive và các nhà điều hành nền tảng UniLive thay đổi sẽ cùng bạn thực hiện Thỏa thuận này và cung
      cấp Dịch vụ cho bạn. Các nhà khai thác nền tảng UniLive cũng có thể được thêm vào do các dịch vụ mới được cung cấp
      bởi nền tảng UniLive, chẳng hạn như việc bạn sử dụng các dịch vụ mới, được coi là bạn đồng ý rằng các nhà khai
      thác nền tảng UniLive tương ứng với các dịch vụ mới sẽ thực hiện Thỏa thuận này với bạn. Khi bạn có tranh chấp về
      việc sử dụng một dịch vụ trong Nền tảng UniLive, bên tranh chấp là nhà điều hành Nền tảng UniLive tương ứng với
      Dịch vụ mà bạn đang sử dụng cụ thể.
    </p>
    <p>
      2- Phạm vi thỏa thuận,
      Thỏa thuận này bao gồm các quy tắc nền tảng UniLive và là một phần không thể tách rời của Thỏa thuận này và có
      hiệu lực pháp lý tương đương với Thỏa thuận này.
      Ngoài ra, nếu bạn sử dụng các sản phẩm và/hoặc dịch vụ do bên thứ ba cung cấp trên Nền tảng của chúng tôi, bạn
      phải tuân thủ các điều khoản dịch vụ của bên thứ ba ngoài việc tuân thủ các thỏa thuận trong Thỏa thuận này.
    </p>
    <p>
      3. Sửa đổi thỏa thuận,
      UniLive có thể sửa đổi Thỏa thuận này khi cần thiết cho những thay đổi như luật pháp và quy định, chính sách quốc
      gia, điều kiện kỹ thuật và chức năng sản phẩm và UniLive sẽ phát hành Thỏa thuận sửa đổi. Một khi nội dung trên
      được công bố chính thức, UniLive sẽ cung cấp cho người dùng một cách thích hợp (nhắc nhở cửa sổ đạn, công bố trang
      web, thông báo hệ thống, cụ thể lấy thực tế làm chuẩn). Nếu bạn có thắc mắc về nội dung Thỏa thuận sửa đổi hoặc
      thông tin nhắc nhở trang, bạn có thể tham khảo ý kiến thông qua dịch vụ khách hàng chính thức của nền tảng UniLive
      để chúng tôi có thể giải thích và giải thích cho bạn. Bằng cách xác nhận bằng cách nhấp vào trang hoặc bằng cách
      khác, chẳng hạn như tiếp tục sử dụng, bạn đồng ý với Thỏa thuận sửa đổi.
    </p>
    <p>1. Phạm vi cấp phép phần mềm</p>
    <p>
      1.1
      Việc bạn sử dụng Dịch vụ có thể yêu cầu tải xuống Phần mềm UniLive, và đối với Phần mềm UniLive và công nghệ của
      nó, nếu có, UniLive chỉ cấp cho bạn một giấy phép cá nhân, có thể thu hồi, không thể chuyển nhượng và không độc
      quyền. Bạn chỉ có thể tải xuống, cài đặt, sử dụng và chạy Phần mềm UniLive cho các mục đích sử dụng Dịch vụ.
    </p>
    <p>
      1.2
      Tất cả các quyền khác không được ủy quyền rõ ràng trong Điều này và các điều khoản khác của Thỏa thuận này vẫn
      được UniLive bảo lưu và bạn phải có sự cho phép bằng văn bản của UniLive để thực hiện các quyền được bảo lưu này.
      UniLive không thực hiện bất kỳ quyền bảo lưu nào và không cấu thành sự từ bỏ quyền bảo lưu.
    </p>
    <p>
      2. Mua lại phần mềm
    </p>
    <p>
      2.1 Bằng cách sử dụng Phần mềm UniLive và các dịch vụ liên quan, bạn có thể tải xuống Ứng dụng UniLive thông qua
      cài đặt sẵn, tải xuống từ bên thứ ba được UniLive ủy quyền, chẳng hạn như App Store, v.v.
    </p>
    <p>
      2.2 Nếu bạn lấy Phần mềm UniLive từ bên thứ ba không được UniLive hoặc không được UniLive cấp phép, UniLive không
      thể đảm bảo rằng Phần mềm sẽ hoạt động bình thường và sẽ không chịu trách nhiệm về những thiệt hại do đó gây ra
      cho bạn.
    </p>
    <p>
      3. Cài đặt và gỡ cài đặt phần mềm
    </p>
    <p>
      3.1 UniLive có thể đã phát triển các phiên bản phần mềm khác nhau cho các thiết bị đầu cuối, hệ thống khác nhau,
      v.v., vui lòng chọn tải xuống phiên bản phù hợp để cài đặt theo tình hình thực tế.
    </p>
    <p>
      3.2 Sau khi tải xuống trình cài đặt, vui lòng làm theo các bước được nhắc nhở để cài đặt chính xác.
    </p>
    <p>
      3.3 Nếu bạn không còn cần sử dụng Phần mềm hoặc cần cài đặt phiên bản mới của Phần mềm, bạn có thể tự gỡ cài đặt.
      Nếu bạn sẵn sàng giúp UniLive cải thiện dịch vụ sản phẩm của mình, chúng tôi rất mong được bạn thông báo về lý do
      gỡ cài đặt.
    </p>
    <p>
      4. Cập nhật phần mềm
    </p>
    <p>
      4.1 Để cải thiện trải nghiệm người dùng và tinh chỉnh Nội dung Dịch vụ, UniLive sẽ không ngừng nỗ lực phát triển
      Dịch vụ mới và cung cấp cho bạn các bản cập nhật phần mềm theo thời gian (các bản cập nhật này có thể ở dạng thay
      thế phần mềm, sửa đổi, nâng cấp tính năng, nâng cấp phiên bản, v.v.).
    </p>
    <p>
      4.2 Để đảm bảo tính bảo mật và tính nhất quán của Phần mềm và Dịch vụ này, UniLive có quyền cập nhật Phần mềm mà
      không cần thông báo cụ thể cho bạn, hoặc thay đổi hoặc hạn chế một số hiệu ứng chức năng của Phần mềm.
    </p>
    <p>
      4.3 Sau khi phát hành phiên bản mới của Phần mềm này, tất cả hoặc một phần chức năng của phiên bản cũ hơn của Phần
      mềm có thể không khả dụng. UniLive không đảm bảo rằng các phiên bản phần mềm cũ hơn sẽ tiếp tục có sẵn và cung cấp
      dịch vụ khách hàng tương ứng, vì vậy hãy tải xuống phiên bản mới nhất kịp thời.
    </p>
    <h3>Đăng ký, sử dụng và hủy tài khoản</h3>
    <p>
      1. Đăng ký
      Bạn có thể tạo một tài khoản UniLive để sử dụng một số tính năng và dịch vụ của nền tảng của chúng tôi. Bạn có thể
      hoàn tất đăng ký/đăng nhập tài khoản UniLive trực tiếp trên nền tảng của chúng tôi.
    </p>
    <p>
      2. Sử dụng
    </p>
    <p>
      2.1 Người dùng có trách nhiệm giữ an toàn thông tin tài khoản UniLive và mật khẩu tài khoản, và Người dùng phải
      chịu trách nhiệm pháp lý đối với tài khoản UniLive và các hành vi dưới mật khẩu. Người dùng đồng ý không tiết lộ
      thông tin tài khoản và mật khẩu cho người khác trong bất kỳ trường hợp nào, vui lòng lưu ý rằng UniLive cũng sẽ
      không chủ động yêu cầu mật khẩu tài khoản của bạn. Khi bạn nghi ngờ tài khoản của mình bị đánh cắp, vui lòng thông
      báo cho UniLive ngay lập tức. Về bảo mật tài khoản, UniLive sẽ làm việc ở mức độ kỹ thuật hiện tại để bảo vệ tài
      khoản của bạn ở phía máy chủ và liên tục cập nhật các biện pháp kỹ thuật. Do bạn chủ động tiết lộ hoặc do bạn bị
      người khác tấn công, lừa đảo gây ra tổn thất và hậu quả, bạn có thể thông qua các biện pháp cứu trợ như tư pháp,
      hành chính để bồi thường cho người vi phạm bản quyền. Nếu bạn cần hỗ trợ UniLive, UniLive sẽ cố gắng hỗ trợ bạn
      trong khuôn khổ pháp lý tại thời điểm đó.
    </p>
    <p>
      2.2 Quyền sở hữu tài khoản UniLive thuộc sở hữu của Công ty TNHH Công nghệ UniLive Bắc Kinh, sau khi người dùng
      hoàn thành thủ tục đăng ký, tài khoản UniLive đã đăng ký chỉ giới hạn cho bạn sử dụng. Đồng thời, vì hành vi tài
      khoản của bạn (bao gồm nhưng không giới hạn ở việc ký kết các thỏa thuận trực tuyến, đăng thông tin, mua hàng hóa
      và dịch vụ và tiết lộ thông tin) đại diện cho hành vi của bạn, bạn nên giữ thông tin tài khoản và mật khẩu của
      mình và chịu trách nhiệm về kết quả của hành vi tài khoản của bạn, bạn không được cho mượn, cho thuê, tặng, chuyển
      nhượng, bán hoặc cho phép người khác sử dụng tài khoản UniLive dưới bất kỳ hình thức nào mà không có sự đồng ý
      bằng văn bản của UniLive. Nếu tài khoản của bạn bị thao tác hoặc sử dụng không phải của riêng bạn, vui lòng thông
      báo cho UniLive ngay lập tức để tránh tổn thất và hậu quả mở rộng; Nếu UniLive xác định rằng việc sử dụng tài
      khoản UniLive của bạn có thể gây nguy hiểm cho sự an toàn của tài khoản và/hoặc bảo mật thông tin phần mềm UniLive
      của bạn, dựa trên các cân nhắc về bảo mật tài sản và thông tin của bạn, bạn đồng ý rằng UniLive có thể ngừng cung
      cấp dịch vụ cho đến khi bạn yêu cầu khôi phục một cách đáng tin cậy và mối đe dọa đối với bảo mật thông tin phần
      mềm UniLive của bạn đã được loại bỏ.
    </p>
    <p>
      2.3 Bạn hiểu và cam kết rằng thông tin tài khoản UniLive mà bạn thiết lập sẽ không vi phạm luật pháp và quy định
      quốc gia, chính sách và các quy tắc liên quan đến các dịch vụ do UniLive cung cấp. Thông tin đăng ký như tên tài
      khoản, số UniLive, hình đại diện, giới thiệu cá nhân và dữ liệu cá nhân của bạn không được xuất hiện bất hợp pháp
      hoặc xấu, không được đăng ký tài khoản UniLive hoặc thiết lập thông tin tài khoản UniLive bằng tên người khác (bao
      gồm nhưng không giới hạn ở việc mạo danh tên, tên, nhãn hiệu, tên, chân dung, hình đại diện của người khác hoặc
      các yếu tố nhận dạng độc đáo hoặc đủ để gây nhầm lẫn) mà không có sự cho phép của người khác, không được đăng ký
      tài khoản UniLive bằng cách đăng ký thường xuyên, đăng ký số lượng lớn, v.v.
    </p>
    <p>
      2.4 Căn cứ vào quy định pháp luật và quy định quốc gia và yêu cầu chính sách quản lý, để tiện cho công chúng thực
      hiện giám sát và xây dựng trật tự cộng đồng tốt vì lợi ích công cộng, UniLive sẽ hiển thị thông tin về địa chỉ
      Giao thức Internet (IP) mà bạn sử dụng tài khoản của mình trong phạm vi hợp lý tại các vị trí như trang thông tin
      cá nhân tài khoản của bạn, vị trí, hình thức, loại hình thông tin v.v. để hiển thị các sắp xếp liên quan theo quy
      định pháp luật và quy định liên quan và tình hình hiển thị thực tế, bạn hoàn toàn biết và đồng ý với điều này.
    </p>
    <p>
      2.5 Để bảo vệ an ninh tài khoản và ngăn chặn các trường hợp như tài khoản bị đánh cắp, đôi khi chúng tôi có thể sử
      dụng một hoặc nhiều cách để xác minh danh tính người dùng đã đăng ký (ví dụ: xác minh qua email, v.v.), nếu người
      dùng không vượt qua thành công, chúng tôi có lý do hợp lý để nghi ngờ tài khoản bị đánh cắp và có thể tùy thuộc
      vào tình huống nghiêm trọng mà đơn phương quyết định có đình chỉ hoặc chấm dứt việc tiếp tục cung cấp sản phẩm
      và/hoặc dịch vụ của chúng tôi cho tài khoản đó hay không. Bạn đồng ý rằng chúng tôi thực hiện các biện pháp nêu
      trên để bảo vệ sự an toàn của tài khoản người dùng và chúng tôi không phải chịu trách nhiệm bất hợp lý.
    </p>
    <p>
      2.6 Nếu người dùng không đăng nhập vào tài khoản UniLive trong hai tháng liên tiếp sau khi đăng ký, UniLive có
      quyền đóng băng, thu hồi, thay thế, hủy tài khoản, đồng thời có quyền xóa, xóa bất kỳ hồ sơ nào của tài khoản
      trong cơ sở dữ liệu phần mềm UniLive (bao gồm nhưng không giới hạn ở thông tin đăng ký, tài sản ảo, v.v.) để tránh
      lãng phí tài nguyên, bất kỳ tổn thất nào do người dùng tự chịu.
    </p>
    <p>
      2.7 Nếu tài khoản của bạn bị đóng băng, bạn có thể kích hoạt lại tài khoản của mình thông qua các phương thức xác
      minh khác được cung cấp bởi nền tảng UniLive.
    </p>
    <p>
      3. Đăng xuất
      Chúng tôi cung cấp cho bạn khả năng đăng xuất tài khoản UniLive để bạn có thể đăng xuất tài khoản của mình thông
      qua các phương thức đăng xuất trực tuyến được cung cấp bởi nền tảng UniLive, bằng cách liên hệ với dịch vụ khách
      hàng của chúng tôi hoặc bằng cách khác do chúng tôi cung cấp.
    </p>
    <h3>V. Bảo vệ thông tin cá nhân của người dùng</h3>

    <p>
      Chúng tôi biết tầm quan trọng của thông tin cá nhân đối với bạn, vì vậy chúng tôi rất coi trọng việc bảo vệ quyền
      riêng tư và thông tin cá nhân của bạn, đồng thời xử lý và xử lý thông tin cá nhân của bạn với nghĩa vụ thận trọng
      cao. Trong quá trình bạn sử dụng các sản phẩm và/hoặc dịch vụ của chúng tôi, chúng tôi sẽ sử dụng các biện pháp kỹ
      thuật liên quan và các biện pháp bảo mật khác để bảo vệ thông tin cá nhân của bạn.
    </p>
    <p>
      Trong quá trình bạn đăng ký tài khoản hoặc sử dụng các dịch vụ liên quan, chúng tôi yêu cầu bạn hợp tác cung cấp
      một số thông tin cần thiết, chẳng hạn như hộp thư của bạn, nếu bạn không đồng ý ủy quyền thông tin cá nhân cần
      thiết cho các dịch vụ liên quan, bạn không thể sử dụng các dịch vụ đó hoặc bị hạn chế trong quá trình sử dụng.
    </p>
    <p>
      3. Chúng tôi rất coi trọng việc quản lý thông tin cá nhân của bạn và cố gắng hết sức để bảo vệ quyền tra cứu, sao
      chép, sửa chữa, bổ sung, xóa bỏ, rút lại sự đồng ý và hủy bỏ tài khoản, khiếu nại tố cáo của bạn đối với thông tin
      cá nhân để bạn có khả năng hiểu và bảo đảm an toàn thông tin cá nhân của mình.
    </p>
    <p>
      4. Chúng tôi rất chú trọng đến việc bảo vệ thông tin cá nhân của trẻ vị thành niên. Nếu bạn là trẻ vị thành niên
      dưới 18 tuổi, bạn nên có sự đồng ý trước của cha mẹ hoặc người giám hộ khác trước khi sử dụng Dịch vụ của UniLive.
    </p>
    <p>
      Để biết thêm thông tin về cách UniLive thu thập, sử dụng, lưu trữ và bảo vệ thông tin cá nhân của bạn và quyền của
      bạn, vui lòng truy cập Chính sách bảo mật của UniLive để biết thêm thông tin.
    </p>
    <h3>Điều khoản sử dụng trẻ vị thành niên</h3>
    <p>
      Nếu bạn là trẻ vị thành niên dưới 18 tuổi, bạn nên đọc Thỏa thuận này và sử dụng Phần mềm UniLive và các dịch vụ
      liên quan với sự giám hộ, hướng dẫn và sự đồng ý của người giám hộ.
    </p>
    <p>
      2. UniLive coi trọng việc bảo vệ thông tin cá nhân của trẻ vị thành niên, người dùng vị thành niên khi điền thông
      tin cá nhân, vui lòng tăng cường ý thức bảo vệ cá nhân và đối xử thận trọng, vui lòng sử dụng đúng phần mềm
      UniLive và các dịch vụ liên quan theo hướng dẫn của người giám hộ.
    </p>
    <p>
      3. Trẻ vị thành niên sử dụng phần mềm này và các dịch vụ liên quan phải học cách sử dụng mạng một cách chính xác
      trong phạm vi hợp lý dưới sự hướng dẫn giám sát của người giám hộ, tránh bị ám ảnh bởi không gian mạng ảo, hình
      thành thói quen lên mạng tốt, phải giỏi học tập trên mạng, không xem thông tin xấu; Phải trung thực giao lưu hữu
      nghị, không sỉ nhục lừa gạt người khác; Phải tăng cường ý thức tự vệ, không tùy ý hẹn hò cư dân mạng;
      Phải giữ gìn an ninh mạng, không phá hoại trật tự mạng; Phải có lợi cho sức khỏe thể xác và tinh thần,
      không sa vào thời gian ảo.
    </p>
    <p>
      4. Người giám hộ của bạn có thể sử dụng các tính năng như nạp tiền, thưởng khi sử dụng các dịch vụ do UniLive cung
      cấp. Bạn, với tư cách là người giám hộ, yêu cầu bạn giữ tài khoản thanh toán của mình để tránh bị người giám hộ sử
      dụng tính năng nạp tiền, thưởng thông qua tài khoản thanh toán của bạn mà không có sự đồng ý của bạn. UniLive sẵn
      sàng làm việc với bạn để tránh những hành vi như vậy.
    </p>
    <p>
      Để bảo vệ tốt hơn quyền riêng tư của trẻ vị thành niên, UniLive nhắc nhở bạn cẩn thận đăng tải nội dung có chứa
      hình ảnh của trẻ vị thành niên, một khi đăng tải, bạn được coi là đã có được sự đồng ý của người có quyền để hiển
      thị thông tin như chân dung, giọng nói của trẻ vị thành niên và cho phép UniLive sử dụng, quản lý nội dung liên
      quan đến trẻ vị thành niên theo Thỏa thuận này. Nếu người có quyền thông báo cho UniLive rằng Nội dung được đăng
      vi phạm quyền của trẻ vị thành niên hoặc dựa trên các cân nhắc khác để bảo vệ quyền của trẻ vị thành niên, UniLive
      có quyền xử lý và thông báo cho bạn về Nội dung mà bạn đăng.
    </p>
    <p>
      Hành vi người dùng yêu cầu bạn phải chịu trách nhiệm về việc bạn sử dụng Phần mềm UniLive và các Dịch vụ liên
      quan, trừ khi được pháp luật cho phép hoặc có sự cho phép trước bằng văn bản của UniLive, việc bạn sử dụng Phần
      mềm UniLive và các Dịch vụ liên quan không được thực hiện các hành vi sau:
    </p>
    <p>
      1.1 Sử dụng bất kỳ plugin, plugin, hệ thống hoặc công cụ của bên thứ ba nào không được UniLive cấp phép hoặc cấp
      phép để can thiệp, phá vỡ, sửa đổi hoặc gây ảnh hưởng khác đến hoạt động bình thường của Phần mềm UniLive và các
      dịch vụ liên quan.
    </p>
    <p>
      1.2 Sử dụng hoặc thực hiện bất kỳ hành vi nào gây nguy hiểm cho an ninh mạng máy tính đối với Phần mềm UniLive và
      các dịch vụ liên quan, bao gồm nhưng không giới hạn ở:
    </p>
    <p>
      1.2.1 Xâm nhập trái phép vào mạng của người khác, can thiệp vào chức năng bình thường của mạng của người khác,
      đánh cắp dữ liệu mạng và các hoạt động nguy hiểm khác;
      1.2.2 Cung cấp các chương trình, công cụ chuyên dùng để xâm nhập mạng, can thiệp vào chức năng bình thường của
      mạng và các biện pháp bảo vệ, đánh cắp dữ liệu mạng và các hoạt động nguy hiểm khác;
      1.2.3 Cung cấp hỗ trợ kỹ thuật, quảng cáo, thanh toán cho những người biết người khác tham gia vào các hoạt động
      gây nguy hiểm cho an ninh mạng;
      1.2.4 Sử dụng dữ liệu trái phép hoặc truy cập vào máy chủ/tài khoản trái phép;
      1.2.5 Truy cập vào mạng máy tính công cộng hoặc hệ thống máy tính của người khác mà không được phép và xóa, sửa
      đổi, tăng cường lưu trữ thông tin;
      1.2.6 Cố gắng phát hiện, quét, kiểm tra các điểm yếu của hệ thống hoặc mạng UniLive hoặc thực hiện các hành vi vi
      phạm an ninh mạng khác mà không được phép;
      1.2.7 Cố gắng can thiệp, phá hoại hoạt động bình thường của hệ thống hoặc trang web UniLive, cố ý phát tán các
      chương trình độc hại hoặc virus và các hành vi phá hoại khác can thiệp vào các dịch vụ thông tin web thông thường;
      1.2.8 Giả mạo tên hoặc một phần tên gói TCP/IP.
    </p>
    <p>
      1.3 Kỹ thuật đảo ngược, biên dịch ngược, biên dịch hoặc cố gắng khám phá mã nguồn của Phần mềm UniLive.
    </p>
    <p>
      1.4 Dữ liệu được giải phóng vào bất kỳ bộ nhớ đầu cuối nào trong quá trình chạy Phần mềm UniLive hoặc Phần mềm
      UniLive, dữ liệu tương tác giữa khách hàng và phía máy chủ trong quá trình chạy Phần mềm và dữ liệu hệ thống cần
      thiết cho hoạt động của Phần mềm UniLive để thu thập, sao chép, sửa đổi, tăng, xóa, treo hoặc tạo bất kỳ sản phẩm
      phái sinh nào dưới hình thức bao gồm nhưng không giới hạn việc truy cập Phần mềm UniLive và các hệ thống liên quan
      bằng cách sử dụng plugin, plugin, điều khiển nhóm hoặc các công cụ/dịch vụ của bên thứ ba không được UniLive ủy
      quyền.
    </p>
    <p>
      1.5 Tăng, cắt giảm, thay đổi chức năng hoặc hiệu quả hoạt động của Phần mềm UniLive bằng cách sửa đổi hoặc làm sai
      lệch các hướng dẫn, dữ liệu trong hoạt động của Phần mềm UniLive, hoặc vận hành hoặc phổ biến phần mềm, phương
      pháp được sử dụng cho các mục đích nêu trên cho công chúng, bất kể các hành động đó có nhằm mục đích thương mại
      hay không.
    </p>
    <p>
      1.6 Xóa thông tin về quyền sở hữu trí tuệ trên Phần mềm UniLive (và các bản sao của nó) và nội dung liên quan (như
      video ngắn) hoặc sửa đổi, xóa hoặc tránh bất kỳ biện pháp kỹ thuật nào được thiết lập để bảo vệ quyền sở hữu trí
      tuệ.
    </p>
    <p>
      1.7 Sử dụng, cho thuê, cho mượn, sao chép, sửa đổi, liên kết, in lại, biên soạn, xuất bản, xuất bản, xuất bản, tìm
      kiếm theo chiều dọc, thiết lập trang web phản chiếu, v.v. đối với nội dung do UniLive sở hữu tài sản trí tuệ hoặc
      nội dung do người dùng khác trong Nền tảng UniLive xuất bản.
    </p>
    <p>
      1.8 Sử dụng tài khoản UniLive để tham gia vào bất kỳ hoạt động hoặc giao dịch bất hợp pháp hoặc có khả năng bất
      hợp pháp (do UniLive đánh giá), bao gồm dạy các phương pháp phạm tội, bán bất kỳ loại thuốc bất hợp pháp nào, rửa
      tiền, lừa đảo, v.v.
    </p>
    <p>
      1.9 Vi phạm pháp luật và quy định, Thỏa thuận này, Quy tắc nền tảng UniLive và các hành vi khác vi phạm quyền và
      lợi ích hợp pháp của người khác.
      Nếu UniLive có lý do hợp lý để tin rằng bất kỳ hành động nào của bạn vi phạm hoặc có thể vi phạm các thỏa thuận
      nêu trên, UniLive có thể đánh giá độc lập và thực hiện các biện pháp cần thiết để xử lý, chấm dứt cung cấp Dịch vụ
      cho bạn mà không cần thông báo trước trong trường hợp khẩn cấp và theo đuổi trách nhiệm pháp lý.
    </p>
    <p>
      2. Thông số kỹ thuật nội dung thông tin
    </p>
    <p>
      2.1 Nội dung thông tin được đề cập trong Điều này có nghĩa là bất kỳ nội dung nào được tạo, sao chép, đăng tải và
      phổ biến bởi Người dùng trong quá trình sử dụng Phần mềm và Dịch vụ, bao gồm nhưng không giới hạn ở thông tin được
      hiển thị trên trang chủ cá nhân như hình đại diện, biệt danh, giới thiệu cá nhân của Tài khoản UniLive hoặc thông
      tin văn bản, hình ảnh, video âm thanh, phát sóng trực tiếp được đăng tải, phổ biến và các nội dung khác được tạo
      ra từ việc sử dụng Tài khoản UniLive hoặc Phần mềm và Dịch vụ.
    </p>
    <p>
      2.2 Bạn hiểu và đồng ý rằng UniLive luôn cam kết cung cấp cho người dùng một môi trường mạng văn minh, lành mạnh
      và có trật tự và bạn không được sử dụng tài khoản UniLive hoặc phần mềm và dịch vụ này để tạo, sao chép, xuất bản,
      phổ biến nội dung như sau can thiệp vào hoạt động bình thường của UniLive và vi phạm quyền và lợi ích hợp pháp của
      người dùng khác hoặc bên thứ ba.
    </p>
    <p>2.3 Quy tắc cơ bản của UniLive đối với việc tải video lên</p>
		<p>
			Hướng dẫn nội dung (tiêu chuẩn cộng đồng)
		</p>
		<p>
			Cấm nội dung bạo lực: Không được tải lên video có hình ảnh bạo lực, gây thương tích, ngược đãi hoặc liên quan đến khủng bố, bao gồm cả cảnh bạo lực giả lập.  
		</p>
		<p>
			Cấm nội dung khiêu dâm hoặc nhạy cảm: Không được tải lên nội dung khỏa thân, gợi dục, khiêu dâm hoặc nội dung dành cho người lớn dưới mọi hình thức.  
		</p>

		<p>
			Cấm phát ngôn thù địch: Không được đăng tải nội dung phân biệt đối xử, công kích hoặc kích động thù hận đối với bất kỳ nhóm nào dựa trên chủng tộc, tôn giáo, giới tính, xu hướng tình dục hoặc quốc tịch.  
		</p>
		<p>
			Cấm bắt nạt và quấy rối: Không được tải lên nội dung sỉ nhục, đe dọa hoặc quấy rối người khác, bao gồm cả hành vi không phù hợp đối với trẻ em.  
		</p>
		<p>
			Cấm hành vi nguy hiểm: Không được hiển thị thử thách nguy hiểm, trò đùa quá trớn hoặc hoạt động bất hợp pháp (ví dụ: sử dụng ma túy, vũ khí) có thể gây thương tích.  
		</p>
    <p>
      3. Quy tắc tham gia hoạt động
    </p>
    <p>
      Các bên thứ ba trên nền tảng UniLive hoặc UniLive sẽ không thường xuyên thực hiện các loại hoạt động, trước khi
      tham gia hoạt động, bạn nên vào trang hoạt động tương ứng để đọc kỹ, hiểu đầy đủ các quy tắc hoạt động, bạn tham
      gia hoạt động được coi là bạn đã đọc đầy đủ, hiểu đầy đủ các quy tắc hoạt động và tự nguyện bị ràng buộc bởi các
      quy tắc hoạt động. Trong quá trình đọc Quy tắc hoạt động, nếu bạn không hiểu hoặc không đồng ý với bất kỳ điều gì
      trong đó, hãy ngừng tham gia vào các hoạt động liên quan.
      Xin lưu ý rằng đối với những người sử dụng các phương tiện không phù hợp để tham gia vào các hoạt động (có nghĩa
      là những người tham gia hoạt động vi phạm các quy định cấm trong Thỏa thuận này hoặc Quy tắc hoạt động), hệ thống
      kiểm soát gió sẽ tự động phân loại và loại bỏ. Bạn đồng ý rằng UniLive có quyền đánh giá độc lập hành vi vi phạm
      dựa trên khả năng phân tích dữ liệu lớn, khả năng nhận dạng kỹ thuật của nền tảng và hủy bỏ tư cách tham gia hoạt
      động, đánh giá giải thưởng và tư cách chiến thắng của người dùng tham gia hoạt động bằng cách sử dụng các phương
      tiện không phù hợp, không công nhận kết quả thu lợi từ việc sử dụng các phương tiện không phù hợp, đồng thời có
      quyền thu hồi phần thưởng đã được trao.
    </p>
    <p>
      4. Chịu trách nhiệm về hành động của mình
    </p>
    <p>
      Bạn hiểu và đồng ý rằng bạn phải chịu trách nhiệm về các hành động của mình trong tài khoản đăng ký của mình, bao
      gồm bất kỳ nội dung, nhận xét và lượt thích, lời chứng thực, tìm kiếm và bất kỳ hậu quả nào phát sinh từ đó. Bạn
      nên tự đánh giá Nội dung trong Dịch vụ và chấp nhận mọi rủi ro phát sinh từ việc sử dụng Nội dung, bao gồm cả
      những rủi ro phát sinh từ sự phụ thuộc vào tính hợp pháp, chính xác, đầy đủ hoặc tiện ích của Nội dung. UniLive
      không thể và sẽ không chịu trách nhiệm về tổn thất hoặc thiệt hại phát sinh từ các rủi ro nêu trên.
    </p>
    <p>
      UniLive tôn trọng và bảo vệ quyền sở hữu trí tuệ, danh dự, tên và quyền riêng tư hợp pháp của bạn và những người
      khác. Bạn đảm bảo rằng văn bản, hình ảnh, video âm thanh, phát sóng trực tiếp, liên kết được tải lên khi sử dụng
      Phần mềm UniLive và các dịch vụ liên quan không vi phạm quyền sở hữu trí tuệ, danh dự, tên, quyền riêng tư và các
      quyền và lợi ích hợp pháp của bất kỳ bên thứ ba nào. Nếu không, UniLive có quyền xóa nội dung bị cáo buộc vi phạm
      đó khi nhận được thông báo từ bên có quyền hoặc bên quan tâm. Đối với tất cả các tuyên bố về quyền của bên thứ ba,
      bạn sẽ tự xử lý và chịu toàn bộ trách nhiệm pháp lý có thể phát sinh từ đó; Trong trường hợp tổn thất (bao gồm
      nhưng không giới hạn ở tổn thất về kinh tế, thiện chí, v.v.) do vi phạm của bạn gây ra cho UniLive hoặc các chi
      nhánh của nó, bạn cũng phải bồi thường đầy đủ cho toàn bộ tổn thất mà UniLive hoặc các chi nhánh của nó phải chịu.
    </p>
    <p>
      1. Người dùng không được tự mình hoặc ủy quyền, cho phép hoặc hỗ trợ bất kỳ bên thứ ba nào thực hiện các hành vi
      sau đây liên quan đến nội dung thông tin trong Phần mềm UniLive và các dịch vụ liên quan mà không có sự cho phép
      bằng văn bản của UniLive:
    </p>
    <p>
      1.1 Sao chép, đọc, áp dụng nội dung thông tin từ phần mềm UniLive và các dịch vụ liên quan để sử dụng thương mại
      bao gồm nhưng không giới hạn ở quảng cáo, tăng lượt đọc, lượt xem;
    </p>
    <p>
      1.2 Sau khi tự ý chỉnh sửa, sắp xếp và sắp xếp nội dung thông tin về Phần mềm UniLive và các Dịch vụ liên quan
      được hiển thị trên các kênh bên ngoài trang nguồn của Phần mềm UniLive và các Dịch vụ liên quan;
    </p>
    <p>
      1.3 Sử dụng bất kỳ hình thức nhận dạng nào bao gồm nhưng không giới hạn ở nhận dạng đặc biệt, mã đặc biệt, v.v.,
      tự mình hoặc hỗ trợ bên thứ ba thực hiện các hành vi không phù hợp như lưu lượng truy cập, hướng dẫn xem, chuyển
      giao, chiếm đoạt thông tin hoặc nội dung của Phần mềm UniLive và các dịch vụ liên quan;
    </p>
    <p>
      1.4 Thu thập bất hợp pháp nội dung thông tin khác từ Phần mềm UniLive và các dịch vụ liên quan.
    </p>
    <p>
      2. Sau khi được UniLive cho phép bằng văn bản, hành vi chia sẻ, chuyển tiếp của người dùng đối với thông tin và
      nội dung của phần mềm UniLive và các dịch vụ liên quan cũng phải tuân thủ các tiêu chuẩn sau:
    </p>
    <p>
      2.1 Đối với dữ liệu liên quan đến việc thu thập, thống kê, thu được các thuật ngữ tìm kiếm liên quan, lượt truy
      cập, phân loại, khối lượng tìm kiếm, lượt truy cập, lượt xem, v.v., dữ liệu trên sẽ không được tiết lộ, cung cấp,
      tiết lộ dưới bất kỳ hình thức nào cho bất kỳ bên thứ ba nào mà không có sự đồng ý trước bằng văn bản của UniLive;
    </p>
    <p>
      2.2 Không được thực hiện bất kỳ thay đổi nào đối với trang web nguồn của Phần mềm UniLive và các Dịch vụ liên
      quan, bao gồm nhưng không giới hạn ở các lối vào như liên kết trang đầu (profile) của Phần mềm UniLive và các Dịch
      vụ liên quan, liên kết đến hệ thống quảng cáo, v.v., cũng như không được thực hiện bất kỳ hình thức che chắn,
      chèn, bật cửa sổ, v.v. để hiển thị trang nguồn của Phần mềm UniLive và các Dịch vụ liên quan;
    </p>
    <p>
      2.3 Các biện pháp an toàn, hiệu quả và chặt chẽ sẽ được thực hiện để ngăn chặn nội dung thông tin từ Phần mềm
      UniLive và các dịch vụ liên quan bị các bên thứ ba thu thập bất hợp pháp thông qua bất kỳ hình thức nào, bao gồm
      nhưng không giới hạn ở các chương trình "nhện";
    </p>
    <p>
      2.4 Không sử dụng nội dung dữ liệu có liên quan cho các mục đích ngoài phạm vi giấy phép bằng văn bản của UniLive,
      cho bất kỳ hình thức bán hàng và sử dụng thương mại nào hoặc tiết lộ, cung cấp hoặc cho phép bên thứ ba sử dụng
      dưới bất kỳ hình thức nào;
    </p>
    <p>
      2.5 Hành vi chia sẻ, chuyển tiếp, sao chép nội dung thông tin về phần mềm UniLive và các dịch vụ liên quan của
      Người dùng cho bất kỳ bên thứ ba nào cũng phải tuân thủ các tiêu chuẩn và tiêu chuẩn khác do UniLive thiết lập cho
      mục đích này.
    </p>
    <h3>giá trị thông số Ki</h3>
    <p>
      Lợi nhuận liên quan như thu nhập bạn tạo ra hoặc phần thưởng bạn nhận được trên Nền tảng UniLive (sau đây gọi là
      "Lợi nhuận liên quan") có thể được rút vào tài khoản thanh toán của chính bạn. Theo luật pháp quốc gia có liên
      quan, các quy định hành chính và các tài liệu quy phạm về thuế, thu nhập liên quan mà bạn tạo ra trên nền tảng
      UniLive sẽ phải trả các khoản thuế liên quan hoặc nộp tờ khai thuế theo quy định của pháp luật. Bạn hiểu và đồng ý
      rằng khi nền tảng UniLive khấu lưu thuế cho bạn hoặc khai thuế thay cho cơ quan thuế theo yêu cầu của cơ quan thuế
      theo quy định của pháp luật, việc thu thập hoặc sử dụng thông tin cá nhân và dữ liệu liên quan đến thuế của bạn
      (bao gồm không giới hạn tên thật, thông tin chứng minh thư và chi tiết liên lạc, v.v.), bạn nên hợp tác với nền
      tảng UniLive để tích cực thực hiện nghĩa vụ thuế của mình.
    </p>
    <h3>X. Sở hữu trí tuệ</h3>
    <p>
      1. Tài sản trí tuệ về nội dung UniLive cung cấp trong Phần mềm và các dịch vụ liên quan (bao gồm nhưng không giới
      hạn ở phần mềm, công nghệ, chương trình, trang web, văn bản, hình ảnh, hình ảnh, âm thanh, video, biểu đồ, thiết
      kế bố trí, tài liệu điện tử, v.v.) thuộc sở hữu của UniLive. Bản quyền, thương hiệu, bằng sáng chế, bí mật thương
      mại và các quyền sở hữu trí tuệ khác của phần mềm mà UniLive dựa vào để cung cấp dịch vụ này đều thuộc sở hữu của
      UniLive. Không ai có thể sử dụng và chuyển nhượng mà không có sự cho phép của UniLive (bao gồm nhưng không giới
      hạn ở việc theo dõi, sao chép, phổ biến, hiển thị, phản chiếu, tải lên, tải xuống nội dung trong Phần mềm này và
      các dịch vụ liên quan thông qua bất kỳ chương trình hoặc thiết bị nào như robot, nhện).
    </p>
    <p>
      Bạn đảm bảo rằng việc đăng tải văn bản, hình ảnh, video, âm thanh được tải lên trong quá trình sử dụng Phần mềm và
      các dịch vụ liên quan là do bạn ban đầu hoặc được ủy quyền hợp pháp (bao gồm cả giấy phép chuyển tiếp) và quyền sở
      hữu trí tuệ của bạn đối với nội dung được tải lên, đăng tải thông qua Phần mềm UniLive thuộc về bạn hoặc chủ bản
      quyền ban đầu.
    </p>
    <p>
      Để chia sẻ và quảng bá tốt hơn tác phẩm của bạn và mở rộng ảnh hưởng của bạn và tác phẩm của bạn, trừ khi bạn có
      thỏa thuận bằng văn bản trái ngược với UniLive, bạn biết, hiểu và đồng ý cấp bản quyền và lợi ích cạnh tranh liên
      quan đến nội dung bạn xuất bản/tải lên (bao gồm nhưng không giới hạn ở văn bản, hình ảnh, video, âm thanh, v.v.)
      trong việc sử dụng Phần mềm UniLive và các dịch vụ liên quan, cũng như nội dung và các yếu tố như chân dung con
      người, biểu diễn, âm nhạc, âm thanh, hội thoại, v.v., miễn phí, không độc quyền và bao gồm nhiều cấp phép chuyển
      tiếp trên toàn thế giới cho UniLive và các công ty liên kết của nó (bao gồm nhưng không giới hạn, quyền sao chép,
      sao chép, bản, hội thoại, v.)Quyền điều chỉnh, quyền dịch thuật, quyền biên soạn, quyền biểu diễn, quyền phổ biến
      mạng thông tin, quyền phát sóng, sản xuất phái sinh, v.v.), Phạm vi cho phép sử dụng được mô tả ở trên bao gồm,
      nhưng không giới hạn, các nền tảng UniLive hoặc các nền tảng, ứng dụng, sản phẩm hoặc thiết bị đầu cuối, trang
      web, trang của bên thứ ba khác hợp tác với UniLive. Bạn đồng ý rằng UniLive có thể sử dụng hoặc phát triển các Nội
      dung trên (toàn bộ hoặc một phần) cho mục đích quảng bá hoặc nâng cấp sản phẩm/tính năng, nghiên cứu sản phẩm/tính
      năng mới bởi các bên thứ ba, theo ý riêng hoặc theo giấy phép của mình, và cam kết sẽ không khẳng định các quyền
      cá nhân hoặc thù lao liên quan đến việc UniLive sử dụng hoặc phát triển các Nội dung trên. Dựa trên các đặc điểm
      của một số chức năng của Dịch vụ, Nội dung bạn tải lên thông qua Phần mềm UniLive có thể được sử dụng bởi những
      người dùng UniLive khác khi họ tạo và xuất bản Nội dung bằng Phần mềm UniLive và các tác phẩm do người dùng tạo
      lại theo thỏa thuận trước đó chỉ có thể được phổ biến trên Nền tảng UniLive hoặc nền tảng bên thứ ba mà UniLive
      hợp tác và không được sử dụng cho bất kỳ mục đích thương mại nào mà không có sự đồng ý của UniLive. Nếu bạn không
      muốn UniLive tiếp tục sử dụng toàn bộ hoặc một phần nội dung được cấp phép, bạn có thể thông báo cho UniLive để
      thu hồi giấy phép đó bằng cách công bố nó.
    </p>
    <p>
      Để nâng cao khả năng tiếp xúc và hiệu quả xuất bản tác phẩm của bạn, bạn đồng ý rằng toàn bộ nội dung bạn đăng khi
      sử dụng Phần mềm UniLive và các dịch vụ liên quan cho phép UniLive tự động đăng đồng bộ tài khoản của bạn lên các
      dịch vụ khách hàng, trang web, trang web và ứng dụng khác do Công ty TNHH Công nghệ UniLive Bắc Kinh hoặc các công
      ty liên kết của nó điều hành, thông tin chưa được bảo mật công khai như ảnh đại diện, biệt danh của tài khoản của
      bạn có thể được đồng bộ. Nếu bạn không muốn tiếp tục cho phép UniLive tự động đồng bộ hóa nội dung đã xuất bản,
      bạn có thể tự động thu hồi giấy phép bằng lời nhắc trên trang hoạt động liên quan đến phần mềm UniLive hoặc thông
      báo cho UniLive bằng cách công bố khác.
    </p>
    <p>
      Bạn xác nhận và đồng ý ủy quyền cho UniLive nhân danh hoặc ủy quyền cho bên thứ ba thay thế nội dung vi phạm quyền
      sở hữu trí tuệ hoặc được cấp phép hợp pháp do bạn tải lên hoặc đăng tải dưới tên UniLive, tại thời điểm đó bạn cần
      hợp tác với các tài liệu hỗ trợ bằng văn bản tương ứng theo yêu cầu của UniLive. Các hình thức bảo vệ quyền bao
      gồm, nhưng không giới hạn ở: giám sát vi phạm, gửi thư bảo vệ quyền, khởi kiện hoặc trọng tài, hòa giải, v.v.,
      UniLive có quyền đưa ra quyết định về các vấn đề bảo vệ quyền và thực hiện chúng một cách độc lập.
    </p>
    <p>
      Nếu bạn thấy rằng phần mềm này và các trang web liên quan được lưu trữ trong bất kỳ nội dung nào vi phạm quyền của
      bạn, vui lòng ngay lập tức thông qua hộp thư [...] unilive.team@gmail.com Thông báo cho UniLive và cung cấp bằng
      chứng sơ bộ về các quyền của bạn, UniLive sẽ xử lý khiếu nại của bạn kịp thời theo quy định của pháp luật.
    </p>
    <p>
      7. UniLive cung cấp hỗ trợ kỹ thuật cho việc phát triển, vận hành phần mềm và các dịch vụ liên quan và có đầy đủ
      quyền đối với tất cả dữ liệu và thông tin phát sinh trong quá trình phát triển và vận hành phần mềm và các dịch vụ
      liên quan.
    </p>
    <p>
      Trong mọi trường hợp, bạn được yêu cầu không sử dụng riêng bất kỳ nhãn hiệu, nhãn hiệu dịch vụ, tên thương mại,
      tên miền, tên trang web hoặc các đặc điểm thương hiệu đáng chú ý khác của UniLive bao gồm "UniLive Technology",
      "UniLive", "unilive.com", v.v. (sau đây gọi chung là "logo"). Bạn không được hiển thị, sử dụng hoặc thực hiện các
      hành vi khác liên quan đến các biểu tượng được mô tả trước trong các Điều khoản này theo bất kỳ cách nào, riêng
      biệt hoặc kết hợp, mà không có sự đồng ý trước bằng văn bản của UniLive. Bạn chịu toàn bộ trách nhiệm pháp lý do
      bạn sử dụng các nhãn hiệu, logo nêu trên của Công ty UniLive để gây thiệt hại cho UniLive hoặc người khác do vi
      phạm Thỏa thuận này.
    </p>
    <h3>Khuyến mãi</h3>
    <p>
      1. Bạn hiểu và đồng ý rằng trong quá trình cung cấp Phần mềm UniLive và các dịch vụ liên quan, chúng tôi có quyền
      đặt và/hoặc gắn quảng cáo thương mại (bao gồm liên kết quảng cáo) hoặc bất kỳ loại thông tin thương mại và phi
      thương mại nào khác (ở trên gọi chung là "Thông tin quảng cáo") theo nhiều cách hoặc bởi các đối tác của chúng
      tôi. Quyền này bao gồm nhưng không giới hạn ở việc UniLive có thể phân phối và/hoặc gắn kết thông tin quảng cáo ở
      bất kỳ vị trí nào trên nền tảng UniLive, chẳng hạn như tài khoản và nội dung mà bạn tải lên, phổ biến. Trong điều
      kiện phù hợp với pháp luật và quy định, hình thức, thời gian, vị trí, nội dung của việc phân phối và/hoặc lắp đặt
      thông tin quảng bá đó không bị can thiệp bởi bất kỳ bên thứ ba nào.
    </p>
    <p>
      2. Về quảng cáo tình dục thương mại
    </p>
    <p>
      2.1 Nếu bạn không muốn nhận quảng cáo có liên quan, bạn có quyền chọn "không quan tâm" đến thông điệp quảng cáo đó
      và việc đẩy quảng cáo tương tự sẽ giảm.
    </p>
    <p>
      2.2 Chúng tôi thực hiện các nghĩa vụ liên quan đến quảng cáo theo quy định của pháp luật, nhưng bạn hiểu và đồng ý
      rằng các đối tác quảng cáo và nội dung quảng cáo không nằm dưới sự kiểm soát của chúng tôi và bạn nên thận trọng
      trong việc đánh giá tính xác thực và độ tin cậy của nội dung quảng cáo. Bạn thực hiện bất kỳ hình thức hành vi
      hoặc phát sinh bất kỳ hình thức tranh chấp nào với các đối tác quảng cáo thông qua các sản phẩm của chúng tôi
      và/hoặc các dịch vụ của chúng tôi là trách nhiệm duy nhất của bạn và được giải quyết, và chúng tôi không chịu bất
      kỳ trách nhiệm nào khác ngoài trách nhiệm pháp lý và quy định rằng chúng tôi phải chịu trách nhiệm, nhưng sẽ cung
      cấp hỗ trợ cần thiết theo yêu cầu của pháp luật.
    </p>
    <p>
      2.3 Nếu bạn không muốn nhận Dịch vụ Thông báo Đẩy UniLive, bạn có quyền tự tắt Dịch vụ trong Quản lý Thông báo Hệ
      thống Điện thoại.
    </p>
    <p>
      1. Phần mềm UniLive và các dịch vụ liên quan bao gồm các liên kết đến thông tin hoặc nội dung thông tin mà UniLive
      có được theo nhiều cách hợp pháp khác nhau, cũng như các dịch vụ riêng lẻ khác do UniLive và các chi nhánh của nó
      hoạt động hợp pháp và UniLive có thể bổ sung, giảm hoặc thay đổi các thiết lập và dịch vụ trong các lĩnh vực đặc
      biệt này theo thời gian. Bạn có thể bật và sử dụng các tính năng dịch vụ riêng lẻ được mô tả ở trên trong phần mềm
      UniLive. Một số dịch vụ riêng lẻ có thể yêu cầu bạn đồng thời chấp nhận một thỏa thuận được thiết lập cụ thể cho
      dịch vụ đó hoặc các quy tắc khác ràng buộc bạn và nhà cung cấp dịch vụ đó. UniLive sẽ cung cấp cho bạn các thỏa
      thuận, quy tắc này một cách nổi bật. Khi bạn đồng ý với một thỏa thuận hoặc bắt đầu sử dụng các dịch vụ nêu trên,
      bạn được coi là đồng thời chấp nhận nội dung thỏa thuận của các thỏa thuận, quy tắc liên quan đến các dịch vụ
      riêng lẻ.
    </p>
    <p>
      Khi bạn sử dụng phần mềm và/hoặc các dịch vụ liên quan do bên thứ ba cung cấp trong Phần mềm UniLive, bạn phải
      tuân thủ các thỏa thuận, quy tắc liên quan của bên thứ ba ngoài việc tuân thủ Thỏa thuận này và Quy tắc Nền tảng
      UniLive. Trong trường hợp tranh chấp, mất mát hoặc thiệt hại phát sinh từ phần mềm của bên thứ ba và/hoặc các dịch
      vụ liên quan do bạn tự giải quyết với bên thứ ba, UniLive không chịu trách nhiệm đối với bạn hoặc bất kỳ bên thứ
      ba nào về vấn đề này.
    </p>
    <h3>XIII Thay đổi, gián đoạn và chấm dứt dịch vụ</h3>
    <p>
      1. Trong phạm vi được pháp luật và quy định cho phép, chúng tôi có thể thay đổi hoặc chấm dứt tạm thời hoặc vĩnh
      viễn các sản phẩm và/hoặc dịch vụ của chúng tôi (hoặc bất kỳ phần nào của chúng tôi), tùy thuộc vào sự phát triển
      kinh doanh của chúng tôi, không chịu trách nhiệm về vi phạm đối với bạn và bất kỳ bên thứ ba nào sau khi được
      thông báo bằng văn bản như thư nội bộ, cập nhật/thông báo chấm dứt trong một khoảng thời gian hợp lý trước.
    </p>
    <p>
      Ngoài các trường hợp nêu trên, chúng tôi có quyền đình chỉ hoặc chấm dứt việc cung cấp sản phẩm và/hoặc dịch vụ
      cho bạn khi:
    </p>
    <p>
      2.1 do bạn chủ động yêu cầu;
    </p>
    <p>
      2.2 Sự hiện diện của bạn hoặc chúng tôi, theo đánh giá độc lập, tin rằng bạn có bất kỳ hành vi nào vi phạm luật
      pháp và quy định quốc gia hoặc chính sách quy định, vi phạm Thỏa thuận này hoặc gây tổn hại đến danh tiếng và lợi
      ích của chúng tôi;
    </p>
    <p>
      2.3 Theo quy định của pháp luật, quy định của chính sách quản lý hoặc theo yêu cầu của cơ quan có thẩm quyền;
    </p>
    <p>
      2.4 Để duy trì các trường hợp khẩn cấp như bảo mật tài khoản và hệ thống;
    </p>
    <p>
      2.5 Bất khả kháng (bất khả kháng có nghĩa là các tình huống khách quan không thể lường trước, không thể tránh khỏi
      và không thể khắc phục, do tính chất đặc biệt của Internet, bất khả kháng được mô tả trong Thỏa thuận này cũng bao
      gồm các tác động đáng kể do hack, điều chỉnh kỹ thuật của ngành viễn thông, tạm thời đóng cửa do kiểm soát của
      chính phủ, sự xâm nhập của virus, v.v. ảnh hưởng đến hoạt động bình thường của Internet);
    </p>
    <p>
      2.6 Những trường hợp không thể cưỡng lại
    </p>
    <p>
      3. Trong trường hợp chấm dứt được mô tả ở trên, bạn và chúng tôi đồng ý xử lý như sau:
    </p>
    <p>
      3.1 Nếu bạn có một giao dịch đang diễn ra trong Nền tảng của chúng tôi, chúng tôi sẽ xử lý hợp lý khi thích hợp;
    </p>
    <p>
      3.2 Trong trường hợp chấm dứt do bạn vi phạm Thỏa thuận này, chúng tôi có quyền, tùy từng trường hợp, yêu cầu bạn
      chịu trách nhiệm về vi phạm tương ứng;
    </p>
    <p>
      3.3 Chúng tôi không bắt buộc phải chịu bất kỳ trách nhiệm pháp lý nào đối với bạn và các bên thứ ba trừ khi được
      quy định bởi luật pháp hoặc do chúng tôi quy định khác.
    </p>
    <h3>Tuyên bố miễn trừ trách nhiệm</h3>
    <p>
      1. Bạn hiểu và đồng ý rằng Phần mềm UniLive và các dịch vụ liên quan có thể bị ảnh hưởng hoặc can thiệp bởi một số
      yếu tố, và UniLive không đảm bảo (bao gồm nhưng không giới hạn) trừ khi được quy định rõ ràng bởi luật pháp và quy
      định:
    </p>
    <p>
      1.1 Phần mềm UniLive và các dịch vụ liên quan hoàn toàn phù hợp với nhu cầu sử dụng của người dùng;
    </p>
    <p>
      1.2 UniLive không bị can thiệp và hoàn toàn kịp thời, an toàn, đáng tin cậy hoặc không có lỗi; và bất kỳ phần mềm,
      dịch vụ hoặc tài liệu nào khác mà người dùng có được thông qua UniLive phù hợp với mong đợi của người dùng;
    </p>
    <p>
      1.3 Mọi lỗi trong phần mềm UniLive sẽ được sửa chữa.
    </p>
    <p>
      2. Đối với thông tin mạng, mật khẩu tài khoản, quảng cáo hoặc khuyến mãi liên quan đến khoản vay hoặc tài sản khác
      bị nghi ngờ, bạn nên thận trọng và tự đánh giá, nếu không bạn sẽ không chịu bất kỳ trách nhiệm pháp lý trực tiếp,
      gián tiếp, ngẫu nhiên, đặc biệt, phái sinh hoặc trừng phạt nào đối với lợi nhuận, uy tín thương mại, tổn thất dữ
      liệu hoặc tổn thất hữu hình hoặc vô hình khác mà bạn phải chịu.
    </p>
    <p>
      Bạn hiểu và đồng ý rằng các yếu tố như bất khả kháng có thể gặp phải trong quá trình sử dụng Phần mềm UniLive và
      các Dịch vụ liên quan. Trong trường hợp bất khả kháng, UniLive sẽ cố gắng khắc phục kịp thời ngay từ đầu, nhưng do
      bất khả kháng gây thiệt hại cho người dùng, người dùng đồng ý UniLive không chịu trách nhiệm.
    </p>
    <p>
      4. UniLive có được quyền xử lý nội dung vi phạm pháp luật theo thỏa thuận này không cấu thành nghĩa vụ hoặc cam
      kết của UniLive và UniLive không thể đảm bảo phát hiện kịp thời hoặc xử lý phù hợp.
    </p>
    <p>
      5. Bạn hiểu và đồng ý rằng UniLive không cung cấp bất kỳ loại bảo đảm hoặc điều kiện nào, rõ ràng hoặc ngụ ý, liên
      quan đến Dịch vụ, bao gồm nhưng không giới hạn ở khả năng bán hàng thương mại, khả năng phù hợp cho một mục đích
      cụ thể, v.v.
    </p>
    <p>
      Bạn hiểu và đồng ý rằng Thỏa thuận này được soạn thảo bởi các bên dựa trên các nguyên tắc tuân thủ luật pháp và
      quy định quốc gia, duy trì trật tự công cộng, bảo vệ quyền và lợi ích hợp pháp của người khác và nâng cao trải
      nghiệm người dùng UniLive. Đối với các vấn đề trong quá trình thực hiện Thỏa thuận liên quan đến việc UniLive phải
      thực hiện nghĩa vụ xem xét, quy định hoặc các nghĩa vụ khác của Nền tảng, UniLive sẽ cố gắng hết sức trong khả
      năng của mình để thực hiện, đánh giá công việc phù hợp với luật pháp và quy định có liên quan, nhưng không đảm bảo
      rằng phán quyết của UniLive hoàn toàn phù hợp với phán quyết của cơ quan tư pháp, cơ quan hành pháp, nếu hậu quả
      phát sinh, Người dùng đã hiểu và đồng ý tự chịu trách nhiệm.
    </p>
    <h3>Xử lý vi phạm</h3>
    <p>
      1. Để đối phó với việc bạn vi phạm Thỏa thuận này hoặc các quy tắc khác của Nền tảng UniLive, UniLive có quyền
      đánh giá độc lập và, tùy từng trường hợp, áp dụng các biện pháp cảnh báo, sửa chữa theo thời hạn, hạn chế một số
      hoặc toàn bộ chức năng của Tài khoản, tạm dừng sử dụng cho đến khi chấm dứt cung cấp Dịch vụ, đóng tài khoản và
      cấm đăng ký lại, v.v., do đó gây ra hậu quả hoặc tổn thất (bao gồm nhưng không giới hạn ở việc làm trống hoặc
      không thể sử dụng dữ liệu người dùng, tài sản ảo và dữ liệu liên quan, sản phẩm hoặc dịch vụ) do bạn tự chịu trách
      nhiệm pháp lý và UniLive không chịu trách nhiệm về việc bạn không thể sử dụng tài khoản và các dịch vụ liên quan
      một cách bình thường, không thể có được tài sản hoặc các quyền lợi khác trong Tài khoản của bạn. UniLive có quyền
      thông báo vi phạm và kết quả xử lý và quyết định có tiếp tục sử dụng tài khoản liên quan hay không tùy thuộc vào
      tình hình thực tế. Đối với các hành vi bị nghi ngờ vi phạm pháp luật, vi phạm pháp luật, UniLive sẽ lưu giữ hồ sơ
      có liên quan và có quyền báo cáo với cơ quan có thẩm quyền theo quy định của pháp luật, phối hợp với cơ quan có
      thẩm quyền điều tra, báo cáo với cơ quan công an, v.v., UniLive có quyền không khôi phục nội dung đã xóa.
    </p>
    <p>
      2. Bạn sẽ tự xử lý và chịu toàn bộ trách nhiệm pháp lý có thể phát sinh từ khiếu nại hoặc khiếu nại của bên thứ ba
      do bạn vi phạm Thỏa thuận này hoặc các quy tắc khác của Nền tảng UniLive. Trong trường hợp UniLive hoặc bên liên
      kết bồi thường cho bất kỳ bên thứ ba nào hoặc chịu hình phạt của cơ quan nhà nước do hành vi vi phạm hoặc vi phạm
      hợp đồng của bạn, bạn cũng phải bồi thường đầy đủ cho UniLive hoặc bên liên kết của nó cho toàn bộ tổn thất do đó.
    </p>
    <h3>16, Khác</h3>
    <p>
      Tiêu đề của tất cả các điều khoản của Thỏa thuận này chỉ thuận tiện cho việc đọc, bản thân nó không có ý nghĩa
      thực tế và không thể được sử dụng làm cơ sở để giải thích ý nghĩa của Thỏa thuận này.
    </p>
    <p>
      2. Các điều khoản của Thỏa thuận này một phần không hợp lệ hoặc không thể thực thi được vì bất kỳ lý do gì, các
      điều khoản còn lại vẫn có hiệu lực và ràng buộc các bên.
    </p>


  </div>
</template>

<script>
export default {
  data() {
    return {
      options: [
        {
          value: "中文",
          label: "中文",
        },
        {
          value: "English",
          label: "English",
        },
        {
          value: "한국인",
          label: "한국인",
        },
        {
          value: "日本語",
          label: "日本語",
        }, {
          value: 'Русский',
          label: 'Русский'//俄国
        }, {
          value: 'Tiếng Việt',
          label: 'Tiếng Việt'//越南
        }, {
          value: 'ภาษาไทย',
          label: 'ภาษาไทย'//泰国
        }
      ],
      value: "",
      language: "",
    };
  },
  created() {
    // url获取参数
    this.language = this.$route.query.language;
    if (this.language == "zh_CN") {
      this.$router.push({
        path: "/UserServiceAgreementZh",
      });
    }
    if (this.language == "en_US") {
      this.$router.push({
        path: "/UserServiceAgreementEn",
      });
    }
    if (this.language == 'ko_KR') {
      this.$router.push({
        path: '/UserServiceAgreementKo'
      })
    }
    if (this.language == 'ja_JP') {
      this.$router.push({
        path: '/UserServiceAgreementJa'
      })
    }
    //俄语
    if (this.language == 'ru_RU') {
      this.$router.push({
        path: '/UserServiceAgreementRu'
      })
    }
    //越南语
    if (this.language == 'vi_VN') {
      this.$router.push({
        path: '/UserServiceAgreementVi'
      })
    }
    //泰语
    if (this.language == 'th_TH') {
      this.$router.push({
        path: '/UserServiceAgreementTh'
      })
    }
  },
  methods: {
    clickChange(value) {
      this.value = value;
      if (value == "中文") {
        this.$router.push({
          path: "/UserServiceAgreementZh",
        });
      }
      if (value == "English") {
        this.$router.push({
          path: "/UserServiceAgreementEn",
        });
      }
      if (value == "한국인") {
        this.$router.push({
          path: "/UserServiceAgreementKo",
        });
      }
      if (value == "日本語") {
        this.$router.push({
          path: "/UserServiceAgreementJa",
        });
      }
      if (value == 'Русский') {
        this.$router.push({
          path: '/UserServiceAgreementRu'
        })
      }
      if (value == 'Tiếng Việt') {
        this.$router.push({
          path: '/UserServiceAgreementVi'
        })
      }
      if (value == 'ภาษาไทย') {
        this.$router.push({
          path: '/UserServiceAgreementTh'
        })
      }
    },
  },
};
</script>

<style>
.container {
  padding: 0.1rem 0.3rem;
}

.lang {
  text-align: right;
  margin-bottom: 0.3rem;
}

h1,
h2 {
  color: #333;
  font-size: 0.5rem;
}

h3 {
  margin-top: 20px;
  font-size: 0.4rem;
}

p {
  margin-bottom: 15px;
  font-size: 0.3rem;
}

li {
  font-size: 0.3rem;
}
</style>
